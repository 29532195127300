#invitatie .card {
  background-image: linear-gradient(90deg, #facddb9b , #f5c1ea79 95%);
    
}

#invitatie .card h4 {
    color: aliceblue;
    text-shadow: 1px 1px 2px rgb(26, 25, 25);
  
}


#invitatie .card #mikey_img {
  width: 150px;
  position: absolute;
  left: 15%;
  top: 60px;
}

@media (max-width:1500px){
  #invitatie .card #mikey_img {
    width: 150px;
    left: 5%;
    top: 60px;
  }
}

@media (max-width: 500px){
  #invitatie .card #mikey_img {
    width: 80px;
    left: 5%;
    top: 150px;
  }
}

.image-gol {

  height: 260px;

}

.dir-img > img {
  width: 160px;
}
.dir-img{
  position: absolute;
  left: 40%;
  top: 740px;
}
.dir-text {
    text-shadow: 1px  1px 2px  whitesmoke;
position: relative;
top: -145px;
left: -10px;
}
.dir-text2 {
    text-shadow: 1px  1px 2px  whitesmoke;
  position: relative;
  top: -125px;
  left: -16px;
}

.clock-img > img {
  width: 100px;
}
.clock-img{
  position: absolute;
  right: 12%;
  top: 820px;
}
.clock-text {
position: relative;
top: -62px;
}

.clock1-img > img {
  width: 100px;
}
.clock1-img{
  position: absolute;
  left: 12%;
  top: 810px;
}
.clock1-text {
position: relative;
top: -55px;
}


.table-img > img {
  width: 220px;
}
.table-img{
  position: absolute;
 left: 2%;
  top: 580px;
}
.table-text {
position: relative;
top: -140px;
}
.table-text2 {
  position: relative;
  top: -150px;
}


.bis-img > img {
  width: 220px;
}
.bis-img{
  position: absolute;
 right: 0.1%;
  top: 580px;
}
.bis-text {
    text-shadow: 2px  2px 5px  white;
position: relative;
top: -20px;
}
.bis-text2 {
  position: relative;
  top: -30px;
}

@media (max-width: 1350px){
  .image-gol {

      height: 350px;
    
    }

.dir-img > img {
  width: 140px;
}
.dir-img{
  position: absolute;
  left: 35%;
  top: 880px;
  
}
.dir-text {
  position: relative;
  top: -125px;
  left: -10px;
  }
  .dir-text2 {
    position: relative;
    top: -120px;
    left: -15px;
  }
  .clock-img > img {
      width: 90px;
    }
    .clock-img{
      position: absolute;
      right: 8%;
      top: 910px;
    }
    .clock-text {
    position: relative;
    top: -55px;
    }
    
    .clock1-img > img {
      width: 90px;
    }
    .clock1-img{
      position: absolute;
      left: 8%;
      top: 910px;
    }
    .clock1-text {
    position: relative;
    top: -55px;
    }
    
    
    .table-img > img {
      width: 190px;
    }
    .table-img{
      position: absolute;
     left: 1%;
      top: 690px;
    }
    .table-text {
    position: relative;
    top: -130px;
    }
    .table-text2 {
      position: relative;
      top: -140px;
    }
    
    
    .bis-img > img {
      width: 180px;
    }
    .bis-img{
      position: absolute;
     right: 0.1%;
      top: 710px;
    }
    .bis-text {
    position: relative;
    top: -20px;
    }
    .bis-text2 {
      position: relative;
      top: -30px;
    }
}

@media (max-width:850px){
    .image-gol {

        height: 350px;
      
      }

  .dir-img > img {
    width: 140px;
  }
  .dir-img{
    position: absolute;
    left: 35%;
    top: 880px;
    
  }
  .dir-text {
    position: relative;
    top: -125px;
    left: -10px;
    }
    .dir-text2 {
      position: relative;
      top: -120px;
      left: -15px;
    }
    .clock-img > img {
        width: 90px;
      }
      .clock-img{
        position: absolute;
        right: 8%;
        top: 910px;
      }
      .clock-text {
      position: relative;
      top: -55px;
      }
      
      .clock1-img > img {
        width: 90px;
      }
      .clock1-img{
        position: absolute;
        left: 8%;
        top: 910px;
      }
      .clock1-text {
      position: relative;
      top: -55px;
      }
      
      
      .table-img > img {
        width: 190px;
      }
      .table-img{
        position: absolute;
       left: 1%;
        top: 690px;
      }
      .table-text {
      position: relative;
      top: -130px;
      }
      .table-text2 {
        position: relative;
        top: -140px;
      }
      
      
      .bis-img > img {
        width: 180px;
      }
      .bis-img{
        position: absolute;
       right: 0.1%;
        top: 710px;
      }
      .bis-text {
      position: relative;
      top: -20px;
      }
      .bis-text2 {
        position: relative;
        top: -30px;
      }
}

@media (max-width: 500px) {
 
  
.image-gol {

   height: 400px;

 }
 
 .dir-img > img {
   width: 130px;
 }
 .dir-img{
   position: absolute;
   left: 35%;
   top: 980px;
 }
 .dir-text {
 position: relative;
 top: -120px;
 left: -10px;
 }
 .dir-text2 {
   position: relative;
   top: -110px;
   left: -15px;
 }
 
 .clock-img > img {
   width: 90px;
 }
 .clock-img{
   position: absolute;
   right: 4%;
   top: 1030px;
 }
 .clock-text {
 position: relative;
 top: -55px;
 }
 
 .clock1-img > img {
   width: 90px;
 }
 .clock1-img{
   position: absolute;
   left: 4%;
   top: 940px;
 }
 .clock1-text {
 position: relative;
 top: -55px;
 }
 
 
 .table-img > img {
   width: 190px;
 }
 .table-img{
   position: absolute;
  left: 1%;
   top: 750px;
 }
 .table-text {
 position: relative;
 top: -130px;
 }
 .table-text2 {
   position: relative;
   top: -135px;
 }
 
 
 .bis-img > img {
   width: 180px;
 }
 .bis-img{
   position: absolute;
  right: 0.1%;
   top: 800px;
 }
 .bis-text {
 position: relative;
 top: -20px;
 }
 .bis-text2 {
   position: relative;
   top: -30px;
 }
   
}
/*380*/
@media (max-width: 380px) {
 
 .dir-img{
   top: 1000px;
 }

 .clock-img{
   top: 1050px;
 }
 
 .clock1-img{
   position: absolute;
   left: 4%;
   top: 960px;
 }
 
 .table-img > img {
   width: 180px;
 }
 .table-img{
   top: 770px;
 }
 .table-text {
 top: -120px;
 }
 .table-text2 {
   top: -130px;
 }
 
 .bis-img > img {
   width: 160px;
 }
 .bis-img{

   top: 800px;
 }
 .bis-text {
    position: relative;
    top: -20px;
    }
    .bis-text2 {
      position: relative;
      top: -30px;
    }
   
}
/*365*/
@media (max-width: 365px) {
 
  .dir-img{
    top: 1000px;
  }
 
  .clock-img{
    top: 1050px;
  }
  
  .clock1-img{
    top:1050px;
  }
  
  .table-img > img {
    width: 180px;
  }
  .table-img{
    top: 800px;
  }
  .table-text {
  top: -120px;
  }
  .table-text2 {
    top: -130px;
  }
  
  .bis-img > img {
    width: 160px;
  }
  .bis-img{
 
    top: 800px;
  }
    
 }